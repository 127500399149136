import { Result } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled.div`
  font-size: 20px;
  line-height: 27px;
`

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <Result
      status="404"
      title="404"
      subTitle={
        <Container>{t`Sorry, the page you visited does not exist.`}</Container>
      }
    />
  )
}

export default PageNotFound
